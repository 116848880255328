import AANL from './assets/imgs/AANL.png'
import IMPULSO from './assets/imgs/IMPULSO.png'
import NL from './assets/imgs/NL.svg'
import economia from './assets/imgs/economia.png'
import { Card } from './components/Card';

const actas = [ '163','164','165','166','167','168','169','170'  ]

function App() {
  return (
    <div className="App">
      <header>
          <div class="logos">
              <img src={IMPULSO} alt="IMPULSO" />
              <img src={economia} alt="secretari de economia"/>
              <img src={NL} alt="Gobierno de nuevo leon"/>
              <img src={AANL} alt="ASI ASCIENDE NUEVO LEON"/>
          </div>
      </header>
      <div className="container">
        {
          actas.map(acta=><Card acta={acta} />)
        }
        
      </div>
    </div>
  );
}

export default App;
