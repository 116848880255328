import React from 'react'

export const ModalPDF = ({acta, setOpenModal}) => {
  return (
    <>
    <div className="overlay" onClick={()=>setOpenModal(false)}></div>
    <div className='pdf-viewer'>
        <embed id="pdf" src={`./actas/${acta}.pdf`} type="application/pdf" width="500px" height={'500px'} ></embed>
    </div>
    </>
  )
}
