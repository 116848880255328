import React, { useState } from 'react'
import { ModalPDF } from './ModalPDF'

export const Card = ({acta}) => {
    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = ()=>{
        setOpenModal(true)
         
    }


  return (
    <>
    
    <div className='card'>
        <img onClick={handleOpenModal} src={`./actas/${acta}.png`} alt={"Acta "+acta} />
        <span>Acta {acta}</span>
        <a href={`./actas/${acta}.pdf`} download={"Acta "+acta}> Descargar </a>
    </div>
    {
        openModal &&
        <ModalPDF acta={acta} setOpenModal={setOpenModal}/>
    }
    </>
  )
}
